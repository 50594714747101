import React from "react"
import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colorRandomize } from "../../utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart(props) {


  
  const total = props.data.map((item,i)=>item.value).reduce((partialSum, a) => partialSum + a, 0);

  const data = {
    labels: props.data.map((item, i) => item.label),
    datasets: [
      {
        label: props.title,
        data: props.data.map((item, i) => item.value),
        backgroundColor: colorRandomize(),
        borderWidth: 1,
      }/*,
      {
        label: '#',
        data: props.item.graphData.map((item, i) => item.value),
        backgroundColor: colorRandomize(colors),
        borderWidth: 1,
      }*/
    ],
  };

  return <>
     <Grid item xs={props.width} sx={{mr:15,padding:5}}><Card  sx={{padding:'5px'}} >
         <Typography color="textSecondary">{props.title} </Typography>
      <CardContent >
        <Bar data={data}
          options={{ maintainAspectRatio: false }} />
      </CardContent>
    </Card> </Grid>
  </>
}