import React from "react"
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, InputLabel, Autocomplete, Select } from "@mui/material";
import { useState } from "react";
import { Grid } from "@mui/material";
import { DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import { UserContext } from '../utils/UserContext';
import ReservationForm from "./ReservationForm";
import axios from "../hooks/axios";
import { getCurrentDate, renderServerMessage } from "../utils/utils";
import { useEffect } from "react";
import { Label } from "@mui/icons-material";
import { Box } from "@mui/system";
import ChangeEventStatus from "./ChangeEventStatus";
import { EVENT_VIEW_MODE_STORE } from "../utils/constants";
import Events from "./Events";
import EventView from "./EventView";




export default function Home(props) {
  const [formData, setFormData] = useState({
    fromDate: getCurrentDate('-'),
    viewMode:'C'
  });
  const [serverMessage, setServerMessage] = useState();

  const [open, setOpen] = React.useState(false);
  const [args, setArgs] = React.useState({});
  const [events, setEvents] = React.useState([]);
  const {userData,setUserData} = React.useContext(UserContext)
  
  const [openChangeStatus,setOpenChangeStatus] = useState();



  function handleClose() {
    setOpen(false);
    setOpenChangeStatus(false);
  }
  useEffect(() => {
    reload();
  }, [])

  function reload() {
    setServerMessage();
    axios.post(`reservation.php`, { ...formData, ot: 'search' }).then((response) => {
      if (response.data.success) {
        setEvents(response.data.data.map((item, i) => ({
          id: item.id,
          text: "Reservation # " + item.id + " ( " + item.status + " )",
          start: item.reservationFrom,
          end: item.reservationTo,
          backColor: item.status == "CONFIRMED" ? "#d4edda" : "#e2e3e5",
          userId:item.userId
        })))
      } else {
        setServerMessage(renderServerMessage(response.data));
      }
    });
  }

  function formChange(event) {
    const { name, value, type,checked, files } = event.target
    console.log(value);
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [name] :type == 'checkbox' ? checked :value,
      }
    })
   // reload();
  }




  return <>
    <div style={{ width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ width:'100%',display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
       
      
       
       <Box sx={{  display: { xs: 'table', md: 'flex' } }}>

        < TextField
          sx={{ mt:{ xs:0,md:1} }}  
          inputProps={{
            min: getCurrentDate("-")
          }}
          type="date"
          id="fromDate"
          name="fromDate"
          autoComplete='off'
          onChange={formChange}
          value={formData.fromDate} />
       <Box sx={{  display: { xs: 'flex' } }}>
<Checkbox    checked={formData.userId} sx={{mt:{xs:1}}} name="userId" id="userId" onChange={formChange} />
<InputLabel sx={{fontSize:5}}  size="small" >  My Reservations</InputLabel>
</Box>


<Box sx={{  display: { xs: 'flex' } }}>
        <Button
          
          color="info"
          onClick={reload}
          variant="contained"
          sx={{ m: {xs:1,md:1} }}
        >  Refresh</Button>

<Button
          
          color="success"
          onClick={()=>setOpen(true)}
          variant="contained"
          sx={{ m: {xs:1,md:1} }}
        >  Add</Button>
        </Box>
        </Box>
      </div>
      <div style={{width:'100%',alignContent: 'center', justifyContent: 'center'}}> {serverMessage}</div>
      <div>
      <DayPilotCalendar
          events={events}
          startDate={formData.fromDate}
          headerDateFormat="dd/MMM"
          businessBeginsHour={0}
          eventMoveHandling="Disabled"
          eventResizeHandling="Disabled"
          businessEndsHour={24}
          cellHeight={14}
          days={10}
          onEventClick={args => {
            console.log(userData);
            if(args.e.data.userId == userData.userId){
              setOpenChangeStatus(true);
              setArgs(args);
              setServerMessage();
            }else{
              setServerMessage(renderServerMessage({success :false , message:'Cannot edit other users transactions'}));
            }
          }}
          onTimeRangeSelected={args => {
            setOpen(true);
            setArgs(args);
          }}
        />
      </div>
    </div>
    {/* <Grid container spacing={2} justify="center" >
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>

      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={8} container justify="center">

      </Grid>
      <Grid item xs={2}></Grid>
    </Grid> */}


    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add new Reservation</DialogTitle>
      <DialogContent fullWidth>
        <ReservationForm args={args} fromDate={formData.fromDate} reload={reload} open={setOpen} serverMessage={setServerMessage} />
      </DialogContent>
    </Dialog>



    <Dialog open={openChangeStatus} onClose={handleClose}>
      <DialogTitle>Change Reservation ( #{args.e?.data.id} )</DialogTitle>
      <DialogContent fullWidth>
        <ChangeEventStatus args={args.e?.data.id} userData={userData}  reload={reload} open={setOpenChangeStatus} serverMessage={setServerMessage} />
      </DialogContent>
    </Dialog>

  </>
}