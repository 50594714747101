import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from '../utils/UserContext';
import { useContext } from 'react';
import {setLocalStorage, renderServerMessage } from '../utils/utils';
import axios from "../hooks/axios";
import { useState } from 'react';
import { COPY_RIGHT, PROJECT_TITLE, COPY_RIGHT_EMAIL } from '../utils/constants';
import { Grid } from '@mui/material';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="mailto:"{...COPY_RIGHT_EMAIL}>
        {COPY_RIGHT}
      </Link>{'-'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();




export default function ForgotPassword( props ) {
    const {userData,setUserData} = useContext(UserContext)
    const [serverMessage,setServerMessage] =useState();



    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   
    if(!validateEmail(data.get('email'))){
      var error={};
      error.success = false;
      error.message = "not valid email";
      setServerMessage(renderServerMessage (error));

      return;
    }

    axios.post('forgot-password.php', {
      email: data.get('email')
    }).then((response) => {
      setServerMessage(renderServerMessage (response.data))
      
      
}).catch(error => {
  setServerMessage(renderServerMessage ({success : false , message : "An Error Has Occured Please Try again"}))
});
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={require('./logo.png')} width="150" />
          <Typography component="h1" variant="h5">
            {PROJECT_TITLE}
          </Typography>
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
            {serverMessage}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
            />
            
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                 back to login page
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>

          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}