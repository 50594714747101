import { Box, TextField, Button,IconButton,Checkbox,Typography, Select } from "@mui/material";
import React, { useEffect } from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import { UserContext } from '../utils/UserContext';
import { Grid } from "@mui/material";
import BreadCrump from "../utils/BreadCrumb";
import AddIcon from '@mui/icons-material/Add';
import {EditOutlined,DeleteOutlined, Search  } from "@mui/icons-material";
import { renderServerMessage, renderTick } from "../utils/utils";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { USER_TYPE_STORE } from "../utils/constants";
import ChangeEventStatus from "./ChangeEventStatus";


export default function Checkout(props) {
    const [formData, setFormData] = useState({})
    const [searchData, setSearchData] = useState({})
    const {userData,setUserData} = React.useContext(UserContext)

    const [rows, setRows] = useState([])
    const [selectionModel, setSelectionModel] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [serverMessage,setServerMessage] =useState();
    const [serverMessageMain,setServerMessageMain] =useState();
    const [pageSize,setPageSize] = useState(10);
    const [rowCount,setRowCount] = useState();
    const [currentPage,setCurrentPage] = useState(1);
    

//TODO Remove saerch and get all upcoming events not paid
    const reload =()=>{
        axios.post(`reservation.php`, {ot:'searchEvents',...searchData,pageSize,currentPage}).then((response) => {
            setRowCount(response.data.dataCount);
            setRows(response.data.data);
    });
}

    useEffect(()=>{
        reload();
    },[currentPage])

    const onDelete = (id) => {
        axios.post(`reservation.php`, {ot:"delete",id:id}).then((response) => {
            if(response.data.success){
                reload()
            }
            setServerMessageMain(renderServerMessage (response.data))  
        });
    };

    const handleClickOpen = (id) => {
        
            setOpen(id);
        
    };
    const handleClose = () => {
        setOpen(false);
       // setCurrentSelectedUser();
    };
    function updateRecord(row) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                row
            }
        })
        handleSubmit()
        return row;
    }


    function searchForm(){
        reload();
    }


    function handleSubmit(e) {
        e&&e.preventDefault();
        console.log(formData);
        
        axios.post(`events.php/save`, formData).then((response) => {
            if(response.data.success){
                reload();
                setFormData({...response.data.data,password:'',confirmPassword:''})
            }
            setServerMessage(renderServerMessage(response.data));
        });
    }

    function searchChange(event) {
        const { name, value } = event.target
         setServerMessage();
         setSearchData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    
    const addEvent = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.post('addEvent.php', {
          email: data.get('email'),
          username: data.get('username'),
          mobile: data.get('mobile'),
          employee: data.get('employee'),
          email: data.get('email'),
          password:data.get('password'),
          isAdmin :data.get('isAdmin')
        
        }).then((response) => {
          setServerMessage(renderServerMessage (response.data))
    }).catch(error => {
      setServerMessage(renderServerMessage ({success : false , message : "An Error Has Occured Please Try again"}))
    });
      };

      
    function formChange(event) {
        const { name, value,checked,type } = event.target
         setServerMessage();
         setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: name=='isActive'|| name=='isAdmin'?checked: value
            }
        })
    }
   

    const columns = [
        {
            field: 'reservationFrom',
            headerName: 'From',
            flex: 1.5
        },
        {
            field: 'reservationTo',
            headerName: 'To',
            flex: 1.5
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1.5
        } ,
        {
            field: 'employee',
            headerName: 'Employee #',
            flex: 0.5
        }, {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1
        },{
            field: 'reservationName',
            headerName: 'Type',
            flex: 1
        },{
            field: 'status',
            headerName: 'Status',
            flex: 1
        },{
            field: 'reason',
            headerName: 'Remark',
            flex: 1
        },{
          field: 'price',
          headerName: 'Price',
          flex: 0.5
        },{
            field: 'actualAmount',
            headerName: 'Total Amt',
            flex: 0.5
          },{
            field:'actions',
            headerName: "Actions",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
            
                return (
                  <Box
                    sx={{
                      backgroundColor: "whitesmoke",
                     
                      display: "flex",
                     
                    }}
                  >
                    <IconButton onClick={() => handleClickOpen(params.row.id)}>
                      <EditOutlined sx={{  color:'#1976d2'}}/>
                    </IconButton>
                    <IconButton sx={{  color:'red'}} onClick={() => onDelete(params.row.id)}>
                      <DeleteOutlined />
                    </IconButton>
                  </Box>
                );
              
            }
          }

    ];




    return <>
        <Grid container spacing={2} sx={{mt:5}}>
        <Grid item xs={12 } hidden={props.viewMode} >
             <BreadCrump title="CheckOut" /> 
        </Grid>

        <Grid item xs={12 }  hidden = {props.viewMode} container justify = "center">
        <TextField type="date"   sx={{ml:2}} id="reservationFrom" label="From " name="reservationFrom" autoComplete='off' onChange={searchChange} value={searchData.reservationFrom}/>
        <TextField type="date" sx={{ml:2}} id="reservationTo" label="To " name="reservationTo" autoComplete='off' onChange={searchChange} value={searchData.reservationTo}/>
        <TextField  sx={{ml:2}} id="employee" label="Employee #" name="employee" autoComplete='off' onChange={searchChange} value={searchData.employee}/>
        <TextField  sx={{ml:2}} id="email" label="Email" name="email" autoComplete='off' onChange={searchChange} value={searchData.email}/>
        <TextField  sx={{ml:2}} id="mobile" label="Mobile #" name="mobile" autoComplete='off' onChange={searchChange} value={searchData.mobile}/>
        <Button variant="outlined" color="info" sx={{m:1,fontFamily:'system-ui'}} onClick={searchForm} startIcon={<SavedSearchIcon sx={{ml:1}} />}>Search</Button>
        </Grid>

        <Grid item xs={12 } container justify = "center">
            
        <Box sx={{mb:2}}>
                {serverMessageMain}
               
        
        </Box >
        <div style={{ width: '100%' }}>
            <DataGrid
            autoHeight
                rows={rows}
                columns={columns}
                processRowUpdate={updateRecord}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                pageSize ={pageSize}
                rowCount={rowCount}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) => {setCurrentPage(newPage)}}
                onSelectionModelChange={setSelectionModel}
            />
        </div>

        <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Reservation ( #{open} )</DialogTitle>
      <DialogContent fullWidth>
        <ChangeEventStatus args={open} userData={userData}  reload={reload} open={setOpen} serverMessage={setServerMessage} />
      </DialogContent>
    </Dialog>
        </Grid>
        </Grid>








    </>




}