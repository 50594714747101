
import React from "react"
import { MenuItem} from "@mui/material";

export const API_URL=process.env.REACT_APP_BASE_URL.trim();
//export const API_URL='https://padel.ezdkclub.com';
//export const API_URL='http://localhost:80/padel/';
export const PROJECT_TITLE = 'EZDK Club Padel';




export const TOKEN_REFRESH_MILLI_SECONDS = 30 * 60 * 1000
export const PROJECT='EZDK.PADEL';
export const TOKEN= PROJECT +'-'+'token';
export const USER_NAME = PROJECT +'-'+'username';
export const USER_ID = PROJECT +'-'+'userId';

export const EXPIRY= PROJECT +'_'+'expiry';
export const COPY_RIGHT_EMAIL = 'ibrahimgaber222@yahoo.com';
export const COPY_RIGHT = 'EZDK ITD';


//export const API_URL ='http://localhost:8888/safwa/';
export const STATUS_STORE_ARRAY =[{value:'',label:''},{value:true ,label: 'مفعل'},{value:false,label:'غير مفعل'}];
export const GRAPH_TYPE_STORE_ARRAY=[{value:'SCALER' ,label: 'SCALER'},{value:'BAR CHART',label:'BAR CHART'},{value:'PIE CHART',label:'PIE CHART'},{value:'LINE CHART',label:'LINE CHART'}];
export const USER_TYPE_STORE_ARRAY =[{value:'',label:''},{value:1 ,label: 'Admin'},{value:0,label:'Normal'}];
export const EVENT_STATUS_STORE_ARRAY =[{value:'',label:''},{value:'CANCELLED' ,label: 'CANCEL'}];
export const EVENT_ADMIN_STATUS_STORE_ARRAY =[{value:'',label:''},{value:'CANCELLED' ,label: 'CANCEL'},{value:'PAID' ,label: 'PAID'},{value:'CLOSED',label:'CLOSED'},{value:'CONFIRMED',label:'CONFIRMED'},{value:'WAITING',label:'WAITING'}];

export const EVENT_VIEW_MODE_STORE_ARRAY =[{value:'C',label:'Calendar View'},{value:'G' ,label: 'Grid View'}];


export const HOURS_STORE_ARRAY=[
{value:'0:00:00' ,label: '00:00 AM'},
{value:'1:00:00' ,label: '1:00 AM'},
{value:'9:00:00' ,label: '9:00 AM'},
{value:'10:00:00' ,label: '10:00 AM'},
{value:'11:00:00' ,label: '11:00 AM'},
{value:'12:00:00' ,label: '12:00 PM'},
{value:'13:00:00' ,label: '01:00 PM'},
{value:'14:00:00' ,label: '02:00 PM'},
{value:'15:00:00' ,label: '03:00 PM'},
{value:'16:00:00' ,label: '04:00 PM'},
{value:'17:00:00' ,label: '05:00 PM'},
{value:'18:00:00' ,label: '06:00 PM'},
{value:'19:00:00' ,label: '07:00 PM'},
{value:'20:00:00' ,label: '08:00 PM'},
{value:'21:00:00' ,label: '09:00 PM'},
{value:'22:00:00' ,label: '10:00 PM'},
{value:'23:00:00' ,label: '11:00 PM'},
{value:'23:59:59' ,label: '12:00 PM'}]

export const CASHIER_COLORS ={
  background : '#fefae0',
  menuColor : '#283618',
  darkButton :'#bc6c25',
  lightButton :'#dda15e',
  addButton : '#606c38',
  fontBlack : 'black',
  lightFont : 'white',
  deleteButton:'#e76f51'

}


  

    function createStore(arr){
      return arr.map((item,i)=><MenuItem  key={i}  value={item.value}>{item.label}</MenuItem>);
  }
export const STATUS_STORE = createStore (STATUS_STORE_ARRAY);
export const GRAPH_TYPE_STORE = createStore(GRAPH_TYPE_STORE_ARRAY);
export const HOURS_STORE = createStore(HOURS_STORE_ARRAY);
export const USER_TYPE_STORE = createStore(USER_TYPE_STORE_ARRAY);
export const EVENT_STATUS_STORE = createStore(EVENT_STATUS_STORE_ARRAY);
export const EVENT_ADMIN_STATUS_STORE = createStore(EVENT_ADMIN_STATUS_STORE_ARRAY);
export const EVENT_VIEW_MODE_STORE = createStore(EVENT_VIEW_MODE_STORE_ARRAY);






