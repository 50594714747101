import {Route, Routes} from "react-router-dom"
import User from "../security/User"
import Home from "../components/Home"

import Calendar from "../components/Calendar"
import ForgotPassword from "../components/ForgotPassword"
import SignIn from "../components/SignIn"
import ReservationType from "../components/ReservationType"
import Users from "../components/user"
import Events from "../components/Events"
import Protected from "./Protected"
import { UserContext } from '../utils/UserContext';
import { useContext } from "react"
import Dashboard from "../components/Dashboard"
import Checkout from "../components/Checkout"







export default function AppRouting(props){
  const {userData,setUserData} = useContext(UserContext)

    return (
        <Routes>
          
          <Route path="/" element={<Calendar />} />
          <Route path="/reservation-type" element={<Protected isAllowed={userData.isAdmin}><ReservationType /></Protected>} />
          <Route path="/Api" element={<Protected isAllowed={userData.isAdmin}><Calendar /></Protected>} />
          <Route path="/user" element={<Protected isAllowed={userData.isAdmin}><Users /></Protected>} />
          <Route path="/calendar" element={<Protected isAllowed={userData.isAdmin}><Calendar/></Protected>} />
          <Route path="/events" element={<Protected isAllowed={userData.isAdmin}><Events viewMode={false}/></Protected>} />
          <Route path="/dashboard" element={<Protected isAllowed={userData.isAdmin}><Dashboard/></Protected>} />
          <Route path="/check-out" element={<Checkout />} />

        </Routes>
    )
}