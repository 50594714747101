import {Route, Routes} from "react-router-dom"

import ForgotPassword from "../components/ForgotPassword"
import ResetPassword from "../components/ResetPassword"
import SignIn from "../components/SignIn"
import Signup from "../components/Signup"

export default function AppSignRouting(props){
    return (
        <Routes>
          <Route path="/" element={<SignIn userData = {props.userData} />} />

          <Route path="/sign-in" element={<SignIn userData = {props.userData} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          


          
        </Routes>
    )
}