import { Box, TextField, Button, InputLabel,Select } from "@mui/material";
import React from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import { Grid } from "@mui/material";
import {  renderServerMessage } from "../utils/utils";
import { EVENT_ADMIN_STATUS_STORE, EVENT_STATUS_STORE } from "../utils/constants";


export default function ChangeEventStatus(props) {
    const [formData, setFormData] = useState({
        actualAmount:0
    });
    const [serverMessage, setServerMessage] = useState();
    function handleSubmit(e) {
        e && e.preventDefault();
        axios.post(`reservation.php`, {...formData,ot:'changeStatus'}).then((response) => {
            if (response.data.success) {
               props.reload();
               props.serverMessage(renderServerMessage(response.data));
               props.open(false);
            }
            setServerMessage(renderServerMessage(response.data));
        });
    }
    function formChange(event) {
        const { name, value } = event.target
        setServerMessage();
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name] : value,
                id : props.args
            }
        })
    }
    return <>
                        {serverMessage}
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <InputLabel  >Status</InputLabel>
                                    <Select
                                        id="status"
                                        required
                                        defaultValue=""
                                        value={formData.status}
                                        name="status"
                                        onChange={formChange}
                                        fullWidth>
                                            
                                        {props.userData.isAdmin ?EVENT_ADMIN_STATUS_STORE:EVENT_STATUS_STORE}
                                    </Select>
                                </Grid>

                                <Grid item xs={12} sx={{display:(formData.status=="PAID"?'block':'none')}}>
                                    <InputLabel  >Actual Amount</InputLabel>
                                    <TextField   
                                        fullWidth
                                        required={formData.status=="PAID"}
                                        type="number"
                                        id="actualAmount"
                                        name="actualAmount"
                                        autoComplete='off'
                                        onChange={formChange}
                                        value={formData.actualAmount}/>
                                </Grid>

                                <Grid item xs={12}>
                                <InputLabel  >Reason</InputLabel>
                                <TextField   
                                        fullWidth
                                        required
                                        multiline={4}
                                        type="text"
                                        id="reason"
                                        name="reason"
                                        autoComplete='off'
                                        onChange={formChange}
                                        value={formData.reason}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    width='50%'
                                    variant="contained"
                                    sx={{ mr: 3, mb: 2, mt: 1 }}
                                >Submit</Button>
                                 </Grid>
                            </Grid>
                        </Box>
    </>
}