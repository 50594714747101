import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { colorRandomize } from "../../utils/utils";
ChartJS.register(ArcElement, Tooltip, Legend);






export default function PieChart(props) {
    

 
  const total = props.data.map((item,i)=>item.value).reduce((partialSum, a) => partialSum + a, 0);
   const data = {
    labels: props.data.map((item,i)=>item.label) ,
    datasets: [
      {
        label: props.title,
        data: props.data.map((item,i)=>item.value),
        backgroundColor: colorRandomize(),
        borderWidth: 1,
      },
    ],
  };
    return <>
         <Grid item xs={props.width} sx={{mr:15,padding:5}}><Card  sx={{padding:'5px'}} >
         <Typography
                    color="textSecondary"
                  >
                    {props.title} ( {total} )
                  </Typography>
         
            <CardContent >
              
            <Pie data={data}  
              options={{ maintainAspectRatio: false }}/>
            </CardContent>
          </Card> </Grid>
    </>
}