import axios from "axios";
import { API_URL, TOKEN } from "../utils/constants";
import { clearLocalStorage } from "../utils/utils";



console.log("REACT_APP_BASE_URL",process.env.REACT_APP_BASE_URL);
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
    }
})



instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(TOKEN);      
      if (token) {
        config.headers["Authorization"] =  token;  
        config.headers["x-access-token"] = token; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );




  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 ) {
          //originalConfig._retry = true;
          clearLocalStorage();
          window.location.replace("/")




        }
      }
      return Promise.reject(err);
    }
  );


  export default instance;
