import { Typography } from "@mui/material"





export default function BreadCrump(props){
    return (
 <Typography sx={{backgroundColor:'#9b9b9b',color:'white',m:'auto',textAlign:'center'}} variant="h3" component="h2">
 {props.title}
 </Typography>
    )
}