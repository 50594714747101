import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouting from './utils/AppRouting';
import { useEffect, useState } from 'react';
 
import SignIn from './components/SignIn';
import MainMenu from './components/MainMenu';
import {UserContext} from './utils/UserContext';
import { USER_ID, USER_NAME } from './utils/constants';
import AppSignRouting from './utils/AppSignRouting';
import instance from './hooks/axios';



function App() {

  

  const username = localStorage.getItem(USER_NAME);   
  const userId = localStorage.getItem(USER_ID);      
   
  const [userData, setUserData] = useState({username,userId});
  useEffect(()=>{

if(userId){
  instance.post('users.php', {ot:'getByID',userId}).then((response) => {
    setUserData({username,userId,isAdmin:response.data.data.admin})

});
  
  
}
},[userId])
  return (
  <>
  <BrowserRouter>
  <UserContext.Provider value={{ userData, setUserData }}>
     
     {!userData.username ?<AppSignRouting userData ={{ userData, setUserData }} />: <><MainMenu/> <AppRouting /></>}

   </UserContext.Provider> 

         
    </BrowserRouter>

  
   </>
  );
}

export default App;

