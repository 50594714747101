import { Box, TextField, Button, IconButton, Checkbox, InputLabel } from "@mui/material";
import React, { useEffect } from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from "@mui/material";
import BreadCrump from "../utils/BreadCrumb";
import AddIcon from '@mui/icons-material/Add';
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import { customToolbar, renderServerMessage, renderTick } from "../utils/utils";
import LockOpenIcon from '@mui/icons-material/LockOpen';

export default function ReservationType(props) {
    const [formData, setFormData] = useState({})
    const [rows, setRows] = useState([])
    const [open, setOpen] = React.useState(false);
    const [openConfirmationDialoge, setOpenConfirmationDialoge] = React.useState(false);

    const [serverMessage, setServerMessage] = useState();
    const [serverMessageMain, setServerMessageMain] = useState();

    const reload = () => {
        axios.post('reservation-type.php', {ot:'get'}).then((response) => {
            if(response.data.data)
            setRows(response.data.data);
        });
    }

    useEffect(() => {
        reload();
    }, [])

    const onDelete = (id) => {
        axios.post(`reservation-type.php`, {id:id,ot:'delete'}).then((response) => {
            if (response.data.success) {
                reload()
            }
            setServerMessageMain(renderServerMessage(response.data))
        });
    };

    const onOpenClosePeriod = () => {
        axios.post(`reservation-type.php`, {id:openConfirmationDialoge,ot:'activate'}).then((response) => {
            if (response.data.success) {
                reload();
            }
            setServerMessageMain(renderServerMessage(response.data));
            setOpenConfirmationDialoge(false);

        });
    };


    const handleClickOpen = (id) => {
        setServerMessage();
        if (id > 0) {
            axios.post(`reservation-type.php`, {id:id,ot:'get-id'}).then((response) => {
                setFormData({ ...response.data.data })
                setOpen(true);
            });
        } else {
            setFormData({});
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setOpenConfirmationDialoge(false);
    };
    function updateRecord(row) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                row
            }
        })
        handleSubmit()
        return row;
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        axios.post(`reservation-type.php`, {...formData,ot:'save'}).then((response) => {
            if (response.data.success) {
                reload();
                setFormData({ ...response.data.data })
            }
            setServerMessage(renderServerMessage(response.data));
        });
    }

    function formChange(event) {
        const { name, value, checked, type } = event.target
        console.log(type)
        setServerMessage();
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type == 'checkbox' ? (checked? 1:0) : value
            }
        })
    }

    function handleReset(e) {
        e && e.preventDefault();

        setFormData({
        })
        setServerMessage();
    }

    

    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        {
            field: 'name',
            headerName: 'Type',
            flex: 1
        }, {
            field: 'price',
            headerName: 'Price',
            flex: 1

        }, {
            field: 'active',
            headerName: 'Is Active',
            flex: 1,
            renderCell: renderTick
        }, {
            field: 'actions',
            headerName: "Actions",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                if (1 === 1) {
                    return (
                        <Box
                            sx={{
                                backgroundColor: "whitesmoke",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <IconButton sx={{ width: 40 }} onClick={() => handleClickOpen(params.row.id)}>
                                <EditOutlined sx={{ m: 2, color: '#1976d2' }} />
                            </IconButton>
                            <IconButton sx={{ width: 40, color: 'red' }} onClick={() => onDelete(params.row.id)}>
                                <DeleteOutlined sx={{ m: 2 }} />
                            </IconButton>
                            
                           
                        </Box>
                    );
                } else return null;
            }
        }
    ];

    return <>
        <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={12} >
                <BreadCrump title="Reservation Types" />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} container justify="center">
                <Box sx={{ mb: 2 }}>
                    {serverMessageMain}
                    <Button variant="outlined" color="success" sx={{ m: 1, fontFamily: 'system-ui' }} onClick={handleClickOpen} startIcon={<AddIcon sx={{ ml: 1 }} />}>
                        Add
                    </Button>
                </Box >
                <br></br>
                <div style={{ height: 1000, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        processRowUpdate={updateRecord}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{
                            Toolbar: customToolbar,
                          }}
                    />
                </div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add new Type</DialogTitle>
                    <DialogContent fullWidth>
                        {serverMessage}
                        <Box component="form" onSubmit={handleSubmit} onReset={handleReset} sx={{ mt: 1 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <InputLabel  >Name</InputLabel>
                                    <TextField
                                        required
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        id="name"
                                        name="name"
                                        autoComplete='off'
                                        onChange={formChange}
                                        value={formData.name}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <InputLabel  >Price</InputLabel>
                                    <TextField
                                        required
                                        sx={{ mt: 2 }}
                                        type = "number"
                                        fullWidth
                                        id="price"
                                        name="price"
                                        autoComplete='off'
                                        onChange={formChange}
                                        value={formData.price}
                            />
                            </Grid>
                               

                                <Grid item xs={6}>  
                        <InputLabel  >  Is Active</InputLabel>
                        <Checkbox title="Is Active" name="active" checked={formData.active} onChange={formChange} />
                    </Grid>

                                
                                
                                <br></br>
                                <Button
                                    type="submit"
                                    width='50%'
                                    variant="contained"
                                    sx={{ mr: 3, mb: 2, mt: 1 }}
                                >
                                    {!formData.id ?
                                        'Add'
                                        :
                                        'Edit'
                                    }</Button>

                                <Button
                                    type="reset"
                                    width='40%'
                                    color="warning"
                                    variant="contained"
                                    sx={{ mr: 3, mb: 2, mt: 1 }}
                                >  Reset</Button>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>




    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={openConfirmationDialoge}
      onClose={handleClose}
    >
      <DialogTitle> Confirmation Message</DialogTitle>
      <DialogContent dividers>
are you sure to change the status
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={onOpenClosePeriod}>Confirm</Button>
      </DialogActions>
    </Dialog>


            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>


       

    </>
}