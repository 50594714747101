import { Box, TextField, Button, IconButton, Checkbox, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import { Grid } from "@mui/material";
import { customToolbar, getCurrentDate, renderServerMessage, renderTick } from "../utils/utils";
import { HOURS_STORE } from "../utils/constants";


export default function ReservationForm(props) {
    const [formData, setFormData] = useState({
        fromHour :props.args.start!=undefined ? props.args.start.getHours():10,
        fromDate : props.fromDate
    });
    const [typesOptions, setTypesOptions] = useState([]);
    const [serverMessage, setServerMessage] = useState();

    const reload = () => {
        axios.post('reservation-type.php', {ot:'get'}).then((response) => {
            setTypesOptions(response.data.data.map((item, i) => (<MenuItem key={i} value={item.id}>{item.name}</MenuItem>)));

        });
    }

    useEffect(() => {
        reload();
    }, [])

    


    function handleSubmit(e) {
        e && e.preventDefault();
        axios.post(`reservation.php`, {...formData,ot:'save'}).then((response) => {
            if (response.data.success) {
               props.reload();
               props.serverMessage(renderServerMessage(response.data));
               props.open(false);
              //  setFormData({ ...response.data.data })
            }
            setServerMessage(renderServerMessage(response.data));
        });
    }

    function formChange(event) {
        const { name, value, checked, type } = event.target
        console.log(type)
        setServerMessage();
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name] : value,
                userId:1,
                status:'',
                price:0
            }
        })
    }

    function handleReset(e) {
        e && e.preventDefault();

        setFormData({
        })
        setServerMessage();
    }

    return <>
                        {serverMessage}
                        <Box component="form" onSubmit={handleSubmit} onReset={handleReset} sx={{ mt: 1 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <InputLabel  >Reservation Type</InputLabel>
                                    <Select
                                        id="typeId"
                                        required
                                        defaultValue=""
                                        value={formData.typeId}
                                        name="typeId"
                                        onChange={formChange}
                                        fullWidth
                                    >
                                        {typesOptions}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                <InputLabel  >Day</InputLabel>
                                <TextField   
                                        fullWidth
                                        type="date"
                                        minutesStep={60}
                                        format="DD-MM-YYYY"
                                        id="fromDate"
                                        name="fromDate"
                                        autoComplete='off'
                                        onChange={formChange}
                                        inputProps={{
                                            min :getCurrentDate("-")
                                          }}
                                        
                                        value={formData.fromDate}/>
                            </Grid>

                            <Grid item xs={6}>
                                <InputLabel  >From</InputLabel>
                                <Select
                                        id="fromHour"
                                        required
                                        defaultValue=""
                                        value={formData.fromHour}
                                        name="fromHour"
                                        onChange={formChange}
                                        fullWidth
                                        
                                    >
                                        {HOURS_STORE}
                                    </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel  >To</InputLabel>
                                <Select
                                        id="toHour"
                                        required
                                        defaultValue=""
                                        value={formData.toHour}
                                        name="toHour"
                                        onChange={formChange}
                                        fullWidth
                                        
                                    >
                                        {HOURS_STORE.map((item, i) => {
      return (
          (item.props.value>formData.fromHour )&&item
      );
    })};
                                    </Select>
                            </Grid>

                           
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    width='50%'
                                    variant="contained"
                                    sx={{ mr: 3, mb: 2, mt: 1 }}
                                >
                                    {!formData.id ?
                                        'Add'
                                        :
                                        'Edit'
                                    }</Button>
                                <Button
                                    type="reset"
                                    width='40%'
                                    color="warning"
                                    variant="contained"
                                    sx={{ mr: 3, mb: 2, mt: 1 }}
                                >  Reset</Button>
                                 </Grid>
                            </Grid>
                        </Box>
    </>
}