import { Box, TextField, Button,IconButton,Checkbox,Typography, Select } from "@mui/material";
import React, { useEffect } from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from "@mui/material";
import BreadCrump from "../utils/BreadCrumb";
import AddIcon from '@mui/icons-material/Add';
import {EditOutlined,DeleteOutlined, Search  } from "@mui/icons-material";
import { renderServerMessage, renderTick } from "../utils/utils";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { USER_TYPE_STORE } from "../utils/constants";


export default function User(props) {
    const API_URL = 'user';
    const [formData, setFormData] = useState({isAdmin:false,isActive:true})
    const [searchData, setSearchData] = useState({})

    const [rows, setRows] = useState([])
    const [selectionModel, setSelectionModel] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [serverMessage,setServerMessage] =useState();
    const [serverMessageMain,setServerMessageMain] =useState();
    const [currentSelectedUser,setCurrentSelectedUser] = useState();
    const [pageSize,setPageSize] = useState(5);
    const [rowCount,setRowCount] = useState();
    const [currentPage,setCurrentPage] = useState(1);
    


    const reload =()=>{
        axios.post(`users.php`, {ot:'search',...searchData,pageSize,currentPage}).then((response) => {
            setRowCount(response.data.dataCount);
            setRows(response.data.data);
    });
}

    useEffect(()=>{
        reload();
    },[currentPage])

    const onDelete = (id) => {
        axios.delete(`${API_URL}/delete?id=${id}`, {}).then((response) => {
            if(response.data.success){
                reload()
            }
            setServerMessageMain(renderServerMessage (response.data))
            
            
        });
    };

    const handleClickOpen = (id) => {
        setServerMessage();
        if(id>0){
            axios.get(`${API_URL}/get-id?id=${id}`, {}).then((response) => {
                setFormData({...response.data,password:'',confirmPassword:''})

                setOpen(true);
            }); 
        }else{
            setFormData({isActive:true,isAdmin:false});  
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setCurrentSelectedUser();
    };
    function updateRecord(row) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                row
            }
        })
        handleSubmit()
        return row;
    }


    function searchForm(){
        reload();
    }


    function handleSubmit(e) {
        e&&e.preventDefault();
        console.log(formData);
        if(formData.password != formData.confirmPassword){
            setServerMessage(renderServerMessage({
                success:false,
                message:'كلمتي السر غير متطابقتين'
            }));

            return;
        }
        axios.post(`${API_URL}/save`, formData).then((response) => {
            if(response.data.success){
                reload();
                setFormData({...response.data.data,password:'',confirmPassword:''})
            }
           // alert(response.data.message);
            setServerMessage(renderServerMessage(response.data));
            //setOpen(false);
            //setFormData({})

        });
    }

    function searchChange(event) {
        const { name, value } = event.target
         setServerMessage();
         setSearchData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    
    const addUser = (event) => {


        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var validationResult = validateFormData(data);
        if(!validationResult.success){
          setServerMessage(renderServerMessage (validationResult));
          return;
        }
    
        axios.post('sign-up.php', {
          email: data.get('email'),
          username: data.get('username'),
          mobile: data.get('mobile'),
          employee: data.get('employee'),
          email: data.get('email'),
          password:data.get('password'),
          isAdmin :data.get('isAdmin')
        
        }).then((response) => {
          setServerMessage(renderServerMessage (response.data))
    }).catch(error => {
      setServerMessage(renderServerMessage ({success : false , message : "An Error Has Occured Please Try again"}))
    });
      };

      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
  
      const validateConfirmPassword = (password,confirmPassword) => {
        return password === confirmPassword;  
      };
  
      const validateStringLength = (str,length) => {
        return str.length <= length;  
      };
  
      const validateFormData = (data) => {
        var error ={
          success:true,
          message : ''
        };
        if(!validateEmail(data.get('email'))){
          error.success = false;
          error.message = "not valid email";
        }
        if(!validateConfirmPassword(data.get('password'),data.get('confirmPassword'))){
          error.success = false;
          error.message = "Confirm Password Doesn`t match Password";
        }
  
        if(!validateStringLength(data.get('employee'),10)){
          error.success = false;
          error.message = "employee # cannot be more than 10 characters";
        }
  
        if(!validateStringLength(data.get('mobile'),20)){
          error.success = false;
          error.message = "mobile # cannot be more than 20 characters";
        }
        return error;
  
      }

    function formChange(event) {
        const { name, value,checked,type } = event.target
         setServerMessage();
         setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: name=='isActive'|| name=='isAdmin'?checked: value
            }
        })
    }
    function handleReset (e){
        e&&e.preventDefault();

        setFormData({
            username:'',
            password:'',
            confirmPassword:'',
            isActive:false,
            isAdmin:false
        })
        setServerMessage();
    }

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.25 },
        {
            field: 'username',
            headerName: 'User Name',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2
        },
        {
            field: 'employee',
            headerName: 'Employee #',
            flex: 1
        }, {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1
        },{
            field: 'active',
            headerName: 'Active',
            flex: 0.25,
            renderCell:renderTick
        },{
            field: 'admin',
            headerName: 'Admin',
            flex: 0.25,
            renderCell:renderTick
        },{
            field:'actions',
            headerName: "Actions",
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              if (1 === 1) {
                return (
                  <Box
                    sx={{
                      backgroundColor: "whitesmoke",
                     
                      display: "flex",
                     
                    }}
                  >
                    <IconButton onClick={() => handleClickOpen(params.row.id)}>
                      <EditOutlined sx={{  color:'#1976d2'}}/>
                    </IconButton>
                    <IconButton sx={{  color:'red'}} onClick={() => onDelete(params.row.id)}>
                      <DeleteOutlined />
                    </IconButton>
                  </Box>
                );
              } else return null;
            }
          }

    ];




    return <>
        <Grid container spacing={2} sx={{mt:5}}>
        <Grid item xs={12 } >
             <BreadCrump title="Registered Users" /> 
        </Grid>

        <Grid item xs={12 }  container justify = "center">
            
        <TextField  sx={{ml:2}} id="email" label="Email" name="email" autoComplete='off' onChange={searchChange} value={searchData.email}/>
        <TextField  sx={{ml:2}} id="mobile" label="Mobile #" name="mobile" autoComplete='off' onChange={searchChange} value={searchData.mobile}/>
        <TextField  sx={{ml:2}} id="employee" label="Employee #" name="employee" autoComplete='off' onChange={searchChange} value={searchData.employee}/>
        <Button variant="outlined" color="info" sx={{m:1,fontFamily:'system-ui'}} onClick={searchForm} startIcon={<SavedSearchIcon sx={{ml:1}} />}>Search</Button>
        <Button variant="outlined" color="success" sx={{m:1,fontFamily:'system-ui'}} onClick={handleClickOpen} startIcon={<AddIcon sx={{ml:1}} />}>Add</Button>



        </Grid>

        <Grid item xs={12 } container justify = "center">
            
        <Box sx={{mb:2}}>
                {serverMessageMain}
               
        
        </Box >
        <div style={{ height: '500px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                processRowUpdate={updateRecord}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                pageSize ={pageSize}
                rowCount={rowCount}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) => {setCurrentPage(newPage)}}
                onSelectionModelChange={setSelectionModel}
            />
        </div>

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
            {serverMessage}
                <Box component="form" onSubmit={addUser} onReset={handleReset} sx={{ mt: 1, width: 300 }}>
                <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
            />
                                    <Select
                                        id="isAdmin"
                                        defaultValue=""
                                        name="isAdmin"
                                        fullWidth
                                        label="User Type"  
                                    >
                                        {USER_TYPE_STORE}
                                    </Select>



            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="UserName"
              name="username"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="mobile"
              label="Mobile"
              name="mobile"
              autoFocus
            />

          <TextField
              margin="normal"
              required
              fullWidth
              id="employee"
              label="Employee Number"
              name="employee"
              autoFocus
            />

          <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
            />


          <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
            />

           
                    <Button
                        type="submit"
                        width ='50%'
                        variant="contained"
                        sx={{ mr: 3, mb: 2 }}
                    >
                        {!formData.id ?
                         'أضف'
                         :
'تعديل'
                        }</Button>

<Button
                        type="reset"
                        width ='40%'
                        color="warning"
                        variant="contained"
                        sx={{ mr: 3, mb: 2 }}
                    > اعادة تعيين</Button>

                    
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>اغـــلاق</Button>
            </DialogActions>
        </Dialog>
        </Grid>
        </Grid>
    </>




}