import React from "react"
import { Alert, IconButton, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { EXPIRY, TOKEN, TOKEN_REFRESH_MILLI_SECONDS, USER_ID, USER_NAME } from "./constants";
import CategoryIcon from '@mui/icons-material/Category';
import ScaleIcon from '@mui/icons-material/Scale';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableBarIcon from '@mui/icons-material/TableBar';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ArchiveIcon from '@mui/icons-material/Archive';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

export function customToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export function renderTick(params) {

    var color = params.value=='1' ? 'green' : 'red'
    return (
        <IconButton sx={{ color: color }} >
            {params.value ? <CheckCircleIcon /> : <DangerousIcon />}
        </IconButton>
    )
}

export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
    }
export function colorRandomize() {
    var unOrderColors = [];
    var color = ['#001219', '#005F73', '#0A9396', '#94D2BD', '#E9D8A6', '#EE9B00', '#CA6702'];
    for (let i = color.length - 1; i >= 0; i--) {
        let unorderedItem =
            color.splice(
                Math.floor(
                    Math.random() * color.length), 1
            );
        unOrderColors.push(unorderedItem);
    }
    return unOrderColors;
}
export function renderServerMessage(response) {
    if (!response) { return ("") }
    var color = response.success ? 'success' : 'error';
    return (
        <Alert sx={{    justifyContent: 'center'}} severity={color}>{response.message}</Alert>
    )

}
export function setLocalStorage(token) {
    localStorage.setItem(TOKEN, token.token)
    localStorage.setItem(EXPIRY, new Date().getTime() + TOKEN_REFRESH_MILLI_SECONDS)
    localStorage.setItem(USER_NAME, token.username);
    localStorage.setItem(USER_ID, token.id)

}
export function clearLocalStorage() {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(EXPIRY)
    localStorage.removeItem(USER_NAME)
    localStorage.removeItem(USER_ID)


}
export function getIcon(item) {

    switch (item.icon.icon) {
        case 'CategoryIcon':
            return <CategoryIcon title={item.title} sx={item.icon.style} />;

        case 'ScaleIcon':
            return <ScaleIcon title={item.title} sx={item.icon.style} />;

        case 'WarehouseIcon':
            return <WarehouseIcon title={item.title} sx={item.icon.style} />;

        case 'LocalShippingIcon':
            return <LocalShippingIcon title={item.title} sx={item.icon.style} />;

        case 'CalendarMonthIcon':
            return <CalendarMonthIcon title={item.title} sx={item.icon.style} />;

        case 'FormatListNumberedIcon':
            return <FormatListNumberedIcon title={item.title} sx={item.icon.style} />;

        case 'ReceiptIcon':
            return <ReceiptIcon title={item.title} sx={item.icon.style} />;

        case 'MonetizationOnIcon':
            return <MonetizationOnIcon title={item.title} sx={item.icon.style} />;

        case 'TableChartIcon':
            return <TableChartIcon title={item.title} sx={item.icon.style} />;

        case 'TableBarIcon':
            return <TableBarIcon title={item.title} sx={item.icon.style} />;

        case 'MenuBookIcon':
            return <MenuBookIcon title={item.title} sx={item.icon.style} />;

        case 'CreditScoreIcon':
            return <CreditScoreIcon title={item.title} sx={item.icon.style} />;

        case 'NotificationsActiveIcon':
            return <NotificationsActiveIcon title={item.title} sx={item.icon.style} />;

        default:
            return <ArchiveIcon title={item.title} sx={item.icon.style} />;
    }

}