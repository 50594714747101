import { Box, TextField, Button } from "@mui/material";
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect } from "react"
import { useState } from "react"
import axios from "../hooks/axios";
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { UserContext } from '../utils/UserContext';
import BreadCrump from "../utils/BreadCrumb";
import { renderServerMessage } from "../utils/utils";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import PieChart from "./dashboard/PieChart";
import BarChart from "./dashboard/BarChart";



export default function Dashboard(props) {
    const [formData, setFormData] = useState({})
    const [searchData, setSearchData] = useState({})
    const {userData,setUserData} = React.useContext(UserContext)

    const [rows, setRows] = useState([])
    const [selectionModel, setSelectionModel] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [serverMessage,setServerMessage] =useState();
    const [serverMessageMain,setServerMessageMain] =useState();
    const [pageSize,setPageSize] = useState(5);
    const [rowCount,setRowCount] = useState();
    const [currentPage,setCurrentPage] = useState(1);


    const [playerCount, setPlayerCount] = useState();
    const [collectedAmt, setCollectedAmt] = useState();
    const [reservationTypeChart, setReservationTypeChart] = useState([]);
    const [dailyReservations, setDailyReservations] = useState([]);

    
    


    const reload =()=>{
        axios.post(`dashboard.php`, {ot:'getPlayerCount'}).then((response) => {
              setPlayerCount(response.data.dataCount);    
    });

    axios.post(`dashboard.php`, {ot:'getCollectedAmount',...searchData}).then((response) => {
      setCollectedAmt(response.data.dataCount);    
    });

    axios.post(`dashboard.php`, {ot:'getReservationType',...searchData}).then((response) => {
      setReservationTypeChart(response.data.data);    
    });

    axios.post(`dashboard.php`, {ot:'getDailyHours',...searchData}).then((response) => {
      setDailyReservations(response.data.data);    
    });

    
}

    useEffect(()=>{
        reload();
    },[currentPage])

    const onDelete = (id) => {
        axios.post(`reservation.php`, {ot:"delete",id:id}).then((response) => {
            if(response.data.success){
                reload()
            }
            setServerMessageMain(renderServerMessage (response.data))  
        });
    };

  
   
   


    function searchForm(){
        reload();
    }


    

    function searchChange(event) {
        const { name, value } = event.target
         setServerMessage();
         setSearchData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    
   


    return <>
        <Grid container spacing={2} sx={{mt:0}}>
        <Grid item xs={12 } >
             <BreadCrump title="Dashboard" /> 
        </Grid>

        <Grid item xs={12 }  container justify = "center" sx={{justifyContent:"center"}}>
        <TextField type="date"  sx={{ml:2}} id="reservationFrom"  name="reservationFrom" autoComplete='off' onChange={searchChange} value={searchData.reservationFrom}/>
        <TextField type="date" sx={{ml:2}} id="reservationTo"  name="reservationTo" autoComplete='off' onChange={searchChange} value={searchData.reservationTo}/>
        <Button variant="outlined" color="info" sx={{m:1,fontFamily:'system-ui'}} onClick={searchForm} startIcon={<SavedSearchIcon sx={{ml:1}} />}>Update</Button>
        </Grid>

        <Grid item xs={12 } container justify = "center">
            
        <Box sx={{mb:2}}>
                {serverMessageMain}
        </Box >
        </Grid>
        <Grid item xs={1 } ></Grid>

        <Grid item xs={2} sx={{mr:15}} ><Card sx={{height:'200px'}} >
            <CardContent >
              <Grid
                container
                spacing={3}
                sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                  >
                    Collected Amount
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    {collectedAmt}
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar
                    sx={{
                      backgroundColor: '#fb8500',
                      height: 56,
                      width: 56
                    }}
                  >
                    <PriceCheckIcon />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
           </Grid>






        <Grid item xs={2} sx={{mr:15}} ><Card sx={{height:'200px'}} >
            <CardContent >
              <Grid
                container
                spacing={3}
                sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                  >
                    Players
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    {playerCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar
                    sx={{
                      backgroundColor: '#8ecae6',
                      height: 56,
                      width: 56
                    }}
                  >
                    <SportsTennisIcon />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
           </Grid>


           <PieChart data={reservationTypeChart} title ={"Type Distribution"} width={3} />

           <BarChart data={dailyReservations} title ={"Daily Reservation"} width={12} />






        </Grid>








    </>




}